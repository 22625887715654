import React from 'react'
import { Link } from 'gatsby'
import Badge from 'react-bootstrap/Badge'
import styles from './movieInfo.module.scss'

// TODO:
// - change info from p to more semantic markup

/* 
MovieInfo is the component for bar displaying movie info (without background under it)
is used in repertoireListMoveCard and in movie-template

we can pass type param (if not === default):
- repertoireLinkElement - whole element works as link (passing linkToMoviePage is obligatory to work) for repertoire list
   for styling whole element gets 'styles.repertoireLink' class
   there are some differences in styling
   some elements gets class xxs-hide (hiding on very narrow screens) only when type is repertoireLinkElement
- default - is not a link (just a bar with movie data) */

const MovieInfo = ({
  type,
  info: {
    titlePl,
    genres,
    onScreen,
    duration,
    ageRating,
    languageVersions,
    linkToMoviePage,
    titleOriginal,
    countries,
    year,
    direction,
    cast,
  },
}) => {
  const isRepertoireLinkElement = type === 'repertoireLinkElement'

  return (
    <div
      className={`${styles.movieInfo} ${
        isRepertoireLinkElement ? styles.repertoireLink : ''
      } element-dark`}
    >
      {/* MOVIE TITLE */}
      {/*
        //TODO - improve instead used workaround - for repertoire page h3 is needed but for an individual movie h1 
        hence presence of linkToMoviePage is used here to distinguish if it's repertoire list or individual movie page
       */}

      {/* if not passed linkToMoviePage whole element works as regular, not as link, even though type is repertoireLinkElement */}
      {isRepertoireLinkElement && linkToMoviePage ? (
        <h3 className={styles.movieTitle}>
          <Link className={`${styles.movieTitle__link}`} to={linkToMoviePage}>
            {titlePl}
          </Link>
        </h3>
      ) : (
        <h1 className={styles.movieTitle}>{titlePl}</h1>
      )}

      {/* MOVIE GENRES */}
      {/* hidden on repertoire link when xxs */}
      <p
        className={`${styles.movieGenres} ${
          isRepertoireLinkElement ? 'xxs-hide' : ''
        } `}
      >
        {genres.map(({ name, id }) => (
          <Badge className={`${styles.badge} badge`} key={id} variant="light">
            {name}
          </Badge>
        ))}
      </p>

      {/* ORIGINAL TITLE (optional - shown if passed) */}
      {titleOriginal && <p className="xxs-hide">{titleOriginal}</p>}

      {/* COUNTRIES & YEAR (optional - shown if passed) */}
      {(countries || year) && (
        <p className="xxs-hide">
          {countries.map(({ name, id }) => (
            <span key={id}>{name}, </span>
          ))}{' '}
          {year}
        </p>
      )}

      {/* DIRECTION (optional - shown if passed) */}
      {direction && <p className="xxs-hide">Reżyseria: {direction}</p>}

      {/* CAST (optional - shown if passed) */}
      {cast && <p className="xxs-hide">Obsada: {cast}</p>}

      {/* AGE RATING & DURATION */}
      {/* hidden on repertoire link when xxs */}
      {ageRating && (
        <p
          className={`${styles.movieAgeDuration} ${
            isRepertoireLinkElement ? 'xxs-hide' : ''
          } `}
        >
          {ageRating && <span>{ageRating.name}</span>}
          {ageRating && duration && <span> | </span>}
          {duration && <span>{duration} min</span>}
        </p>
      )}

      {/* LANGUAGE VERSIONS */}
      {/* hidden on repertoire link when xxs */}
      {languageVersions && !!languageVersions.length && (
        <p
          className={`${styles.movieLanguage} ${
            isRepertoireLinkElement ? 'xxs-hide' : ''
          } `}
        >
          {languageVersions.map(({ name, id }) => (
            <Badge className={`${styles.badge} badge`} variant="light" key={id}>
              {name}
            </Badge>
          ))}
        </p>
      )}

      {/* IN THE CINEMA */}
      {/* hidden on repertoire link when xxs */}
      {(onScreen?.startDate || onScreen?.endDate) && (
        <p
          className={`${styles.movieInCinema} ${
            isRepertoireLinkElement ? 'xxs-hide' : ''
          } `}
        >
          Gramy {onScreen.startDate ? `od ${onScreen.startDate}` : ''}{' '}
          {onScreen.endDate ? `do ${onScreen.endDate}` : ''}
        </p>
      )}
    </div>
  )
}

export default MovieInfo
