import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components'
import BlockContentSanity from '../components/BlockContentSanity/BlockContentSanity'

import SEO from '../components/seo'
import Layout from '../components/layout'
import MovieInfo from '../components/movieInfo'
import MultiBackground from '../components/multiBackground'
import MoviePosterImage from '../components/moviePosterImage'
import YouTubeView from '../components/youTubeView/YouTubeView'
import { uniqByProp } from '../utils/removeDuplicatesFromObjectsArray'
import { forScreen } from '../styles-global/styled-components/styled-responsive'
import MovieProjections from '../components/movieProjections'
import { shadowSecondary } from '../styles-global/styled-components/styled-mixins'

// function that filteres only projections for given movie (with passed movieSlug)
const filterOnlyProjectionsForMovie = ({ projectionDays, movieSlug }) =>
  projectionDays.map(({ day, projections }) => ({
    day,
    projections: projections.filter(
      projection => projection.film.slug.current === movieSlug
    ),
  }))

const MovieTemplate = ({ data: { movie, allSanityProjectionsDay } }) => {
  // below we take currentMovieSlug which will be used to get projections only for current movie

  const {
    titlePl,
    titleOriginal,
    genres,
    countries,
    year,
    posterImage,
    backgroundImage,
    onScreen,
    direction,
    cast,
    duration,
    body,
    ageRating,
    languageVersions,
    trailerUrl,
    slug: { current: currentMovieSlug },
  } = movie

  // from graphQl we get 'projectionDays' hence every projectionDay which contains a projection of current movie
  // but projectionDay contains all projections for this day (for other movies also)
  // const tempProjectionsDays = allSanityProjectionsDay.nodes
  // so we need to 'prune' (get rid of projections for other movies) to have object only with projections for current movie:

  const movieOnlyAllProjections = filterOnlyProjectionsForMovie({
    projectionDays: allSanityProjectionsDay.nodes,
    movieSlug: currentMovieSlug,
  })

  // movieOnlyAllProjections still can have duplicates
  // (graphQl returns e.g. the day twice if the movie has two projections in the day)
  // so we get rid of duplicates (by 'day' prop)

  const uniqueByDay = uniqByProp('day')

  // array of days with projections ready to be shown
  const movieUniqueProjections = uniqueByDay(movieOnlyAllProjections)

  // try to get image assets - if failed - will store (and then pass) undefined
  const backgroundImageFluid = backgroundImage?.asset?.fluid
  const posterImageFluid = posterImage?.asset?.fluid

  return (
    <Layout>
      <SEO title={titlePl} />

      <StyledMovie>
        {/* MOVIE INFO */}
        <div className="movieInfoWrapper">
          {/* MovieInfo is the component for bar displaying movie info (without background under it) */}
          <MovieInfo
            className="movieInfo"
            info={{
              titlePl,
              genres,
              onScreen,
              duration,
              ageRating,
              languageVersions,
              titleOriginal,
              countries,
              year,
              direction,
              cast,
            }}
          />
        </div>

        {/* MOVIE DETAILS */}
        <div className="movieDetails">
          {/* TRAILER */}
          <div>
            <YouTubeView url={trailerUrl} />
          </div>
          {/* DESCRIPTION */}
          <div className="movieDescription">
            <BlockContentSanity blocks={body} />
          </div>
        </div>

        {/* MOVIE SIDEBAR (poster & buy ticket button)  */}
        <div className="sideBar">
          <MoviePosterImage
            img={posterImageFluid} // if no image given - passes undefined - handled by the component
          />
          <div className="sideBarContent">
            <h2 className="visually-hidden">Kup bilet</h2>
            <a
              href="http://epicentrum.hostingasp.pl/"
              className="btn btn-grazynaPrimary btn--fullWidth btn--uppercase"
              variant="grazynaPrimary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Kup bilet
            </a>
            <div className="sideBarProjections">
              <h2 className="visually-hidden">Seanse filmu {titlePl}</h2>
              <MovieProjections projections={movieUniqueProjections} />
            </div>
          </div>
        </div>

        {/* DECORATION BAR - movie bacground under MOVIE INFO bar */}
        <div className="decorationBar">
          <MultiBackground
            img={backgroundImageFluid} // if no image given - passes undefined - handled by the component
            className="decorationBar__bgImage"
            onTopGradients={[
              `linear-gradient(to left, transparent 20%, var(--grayDark) 100%)`,
              `linear-gradient(to right, transparent 20%, var(--grayDark) 100%)`,
            ]}
          />
        </div>
      </StyledMovie>
    </Layout>
  )
}

const StyledMovie = styled.main`
  display: grid;

  //   RESPONSIVE
  grid-template-columns: 1fr;

  /* content (page) max width (does not apply to the decoration bar) - should be moved to global conf, but for now used only here */
  --content-max-width: 1840px;
  --grid-inner-margin: calc((100% - var(--content-max-width)) / 2);

  ${forScreen.sm`
      // content with max width in grid
      // technique (https://www.notion.so/jurekskowron/css-grid-max-width-container-with-full-width-background-1c3c7ce77d894dce85bb3ac196202300)
      grid-template-columns: var(--grid-inner-margin) 1fr 3fr var(--grid-inner-margin);
      grid-template-rows: auto auto 1fr auto;
      gap: 3rem 3rem;
    `}

  .movieInfoWrapper {
    // RESPONSIVE
    grid-row: 1;
    grid-column: 1 / -1;
    margin: 1rem 1rem;

    ${forScreen.sm`
      margin: 3rem 0;
      grid-row: 1;
      grid-column: 3;
    `}
  }

  .movieDetails {
    // RESPONSIVE
    ${forScreen.sm`
      margin: 0;
      grid-row: 2 / span 2;
      grid-column: 3 / -2;
    `}
  }

  .movieDescription {
    // RESPONSIVE
    margin: 0 1rem;

    ${forScreen.sm`
      margin: 0;
    `}
  }

  .sideBar {
    --headingColor: var(--movieSideBarHeadingColor);
    background-color: var(--movieSideBarBgColor);
    color: var(--movieSideBarTextColor);
    border-radius: 0.5rem 0.5rem 0 0;
    overflow: hidden;
    margin-top: 3rem;
    z-index: 1; // has to be over decoration bar
    text-align: center;

    display: flex;
    // for mobile view - first projections and 'buy ticket' then movie poster
    flex-direction: column-reverse;

    ${shadowSecondary}

    img {
      width: 100%;
    }

    .sideBarContent {
      --sideBarInnerMargin: 1rem;
      margin: 1rem;
    }

    .sideBarProjections {
      margin: 2rem 0;
    }

    // RESPONSIVE
    ${forScreen.sm`
      grid-row: 1 / span 2;
      grid-column: 2;

      // for desktop view - first movie poster, then projections and 'buy ticket'
      flex-direction: column;
    `}
  }

  .decorationBar {
    background-color: var(--grayDark);
    height: 100%;
    overflow: hidden;
    position: relative;

    // RESPONSIVE
    grid-row: 1;
    grid-column: 1 / -1;
    // z-index: -10; // can't have negative z-index (is invisible then, under body bg)

    ${forScreen.sm`
      grid-row: 1;
    `}

    // styling element with background image (added with MultipleBackground component
    // we don't want background image to be wider than max width (with additional 200px)
    .decorationBar__bgImage {
      max-width: calc(var(--content-max-width) + 200px);
      margin: 0 auto;
    }
  }
`

export const query = graphql`
  query($slug: String!) {
    movie: sanityFilm(slug: { current: { eq: $slug } }) {
      titlePl
      titleOriginal
      slug {
        current
      }
      year(formatString: "YYYY")
      duration: time
      posterImage {
        asset {
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      backgroundImage {
        asset {
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
      }
      id
      direction
      cast
      genres {
        name
        id
      }
      countries {
        name
        id
      }
      onScreen {
        endDate(formatString: "DD.MM.YYYY")
        startDate(formatString: "DD.MM.YYYY")
      }
      body: _rawBody(resolveReferences: { maxDepth: 10 })
      ageRating {
        id
        name
      }
      languageVersions {
        id
        name
      }
      trailerUrl
    }
    allSanityProjectionsDay(
      filter: {
        projections: {
          elemMatch: { film: { slug: { current: { eq: $slug } } } }
        }
      }
      sort: { fields: day }
    ) {
      nodes {
        day
        projections {
          time
          film {
            titlePl
            slug {
              current
            }
          }
          languageVersion {
            name
          }
        }
      }
    }
  }
`

export default MovieTemplate
