/**
   * 
   Helper function allowing us to removes duplicates 
   from array of objects using ES6 Map

   Duplicates are not necessary same objects.
   This function treats as duplicate when objec has the same 
   value of given prop

   It comes from https://dev.to/marinamosti/removing-duplicates-in-an-array-of-objects-in-js-with-sets-3fep
   (Jonas Winzen's Approach 3 - https://dev.to/misterwhat/comment/a65g)

    USAGE:
    ------
    //import:
    import {uniqByProp} from '../utils/removeDuplicatesFromObjectsArray'

    // create 'wrapping' function passing the prop name:
    const uniqueByDay = uniqByProp("day");

    // remove doubles
    const unifiedArray = uniqueByDay(movieOnlyAllProjections);
 * 
 * */

export const uniqByProp = prop => arr =>
  Array.from(
    arr
      .reduce(
        (acc, item) => (item && item[prop] && acc.set(item[prop], item), acc), // using map (preserves ordering)
        new Map()
      )
      .values()
  )
