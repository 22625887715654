import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

/*
 * COMPONENT FOR SHOWING that there is test day used
 */

const TestDayInfo = ({ testDayLabel }) => (
  <StyledInfo>
    Testowe symulowanie widoku dla dnia: <strong>{testDayLabel}</strong>
  </StyledInfo>
)

TestDayInfo.propTypes = {
  testDayLabel: PropTypes.string.isRequired,
}

export default TestDayInfo

const StyledInfo = styled.div`
  background-color: red;
  color: yellow;
  font-size: 1.5rem;
  padding: 0.5rem;
  text-align: center;
`
