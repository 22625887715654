import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import withLocation from './withLocation'
import { filterMovieProjections } from '../utils/filterRepertoire'
import TestDayInfo from './repertoireList/testDayInfo'

/* 
    * Component which generates and renders view of individual movie active projections

    By default actives projections are these which will occure in the future (including 'today')
    But if testDay passes (in queryParams) it will be treated as today

    testDay is received from withLocation wrapper (it comes from query param)
*/

const MovieProjections = ({ projections, testDay }) => {
  if (projections && projections.length > 0) {
    const activeProjections = filterMovieProjections({
      projectionDays: projections,
      testDay,
    })
    if (activeProjections.length > 0) {
      return (
        <>
          {testDay && (
            <TestDayInfo testDayLabel={testDay.format('YYYY-MM-DD')} />
          )}
          <StyledProjectionsSection>
            {activeProjections.map(projectionDay => (
              <ProjectionDay
                key={projectionDay.day}
                projectionDay={projectionDay}
              />
            ))}
          </StyledProjectionsSection>
        </>
      )
    }
  }

  // if there are no active projections
  return (
    <>
      {testDay && <TestDayInfo testDayLabel={testDay.format('YYYY-MM-DD')} />}
      <StyledProjectionsSection>Brak projekcji</StyledProjectionsSection>
    </>
  )
}

const ProjectionDay = ({
  projectionDay: { day, projections: projectionsOfDay },
}) => (
  <div>
    <h3 className="dayLabel">
      <time dateTime={moment(day).format('YYYY-MM-DD')}>
        <span className="visually-hidden">
          {moment(day).format('D MMMM YYYY - dddd')}
        </span>
        <span aria-hidden="true">{moment(day).format('dd D.MM')}</span>
      </time>
    </h3>
    <ProjectionsOfDay projectionsOfDay={projectionsOfDay} day={day} />
  </div>
)

const ProjectionsOfDay = ({ projectionsOfDay, day }) => (
  <ul className="projectionsList">
    {projectionsOfDay.map(({ time, languageVersion: { name: langVer } }) => (
      <li className="badge projectionBadge" key={time}>
        <time dateTime={`${moment(day).format('YYYY-MM-DD')}T${time}`}>
          <span className="projectionBadge__time">{time}</span>
        </time>

        <span className="projectionBadge__langVer">{langVer}</span>
      </li>
    ))}
  </ul>
)

export default withLocation(MovieProjections)

const StyledProjectionsSection = styled.section`
  .dayLabel {
    margin-top: 1.5rem;
    padding-bottom: 0.1rem;
    border-bottom: var(--movieProjectionsDayLabelUnderlineStyle);
    margin-bottom: 0.2rem;
  }

  .projectionsList {
    padding: 0;
  }

  .projectionBadge {
    --badgeColor: var(--movieProjectionsBadgeColor);
    --badgeTextColor: var(--movieProjectionsBadgeTextColor);
    --badgeFontSize: 0.9rem;

    display: inline-flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    padding-right: 0.2em;
    padding-left: 0.2em;

    margin: 0.15em 0.2em;

    &__langVer {
      font-weight: 400;
    }
    &__langVer,
    &__time {
      padding: 0.15em 0.2em;
    }
  }
`
